import React from 'react'
import handleViewport from 'react-in-viewport'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import './showcase.css'

function Showcase() {

    const one = (props) => {
        const {inViewport, forwardedRef} = props;
        return(
            <div className='parent-box' style={{backgroundColor: 'black', zIndex: 2}}>
                <CSSTransition in={inViewport} exit={false} timeout={400} classNames='slideOver' >
                    <section ref={forwardedRef}>
                        
                        <span className='heroText'>about</span>
                        <div className='contentBox'>
                            <h2>Hi, I'm <b>Roy</b>, a Software Developer in Toronto, Canada majoring in Computer Science and Statistics at the University of Toronto.</h2>
                            <p>I am currently working part-time as a Full-stack Developer, and have experience developing with modern web programming using front-end frameworks such as React.js & Vue.js.
                                I also work on back-end systems utilising Node.js, and accompanying databases such as MySQL and NoSQL.
                            </p>
                        </div>
                    </section>
                </CSSTransition>
            </div>)
    }

    const three = (props) => {
        const {inViewport, forwardedRef} = props;
        return(
            <div className='parent-box' style={{background: 'linear-gradient(#efb350, #d13b3b)', zIndex: 3}}>
                <CSSTransition in={inViewport} exit={false} timeout={400} classNames='slideOver'  >
                    <section ref={forwardedRef}>
                        
                        <span className='heroText'>Pledgeasy</span>
                        <div className='contentBox'>
                            <h3>AWARDED FIRST PLACE AT TD ELEVATE TECH JAM 2019</h3>
                            <p>
                            Pledgeasy is a mobile app aimed at making
                            donations to charities easier, and more rewarding
                            than ever. Seamless integration with banks and a
                            "set it and forget it" system of automatic change
                            roundup from your daily purchases. Track your
                            personal impact towards issues you care about.
                            </p>
                            <p>
                            $10.6 billion CAD was donated to charitable
                            causes in Canada as of 2017, but a shortcoming
                            of the current donation process was the time taken
                            to make donations and inconsistency of processes;
                            we wanted to create a platform for users who are
                            inclined to donate, and allow for them to make
                            consistent, regular donations as easy as possible.
                            </p>
                        </div>
                        <div className='imageBox pledgeasy'>
                            <img height={'750px'} src='/img/pledgeasy_screens.png' alt='Pledgeasy App'/>
                        </div>
                    </section>
                </CSSTransition>
            </div>)
    }

    const four = (props) => {
        const {inViewport, forwardedRef} = props;
        return(
            <div className='parent-box' style={{backgroundColor: '#201e1f', zIndex: 4}}>
                <CSSTransition in={inViewport} exit={false} timeout={400} classNames='slideOver'>
                    <section ref={forwardedRef}>
                        <span className='heroText'>Double Nines</span>
                        <div className='contentBox'> 
                            <p>
                            Double Nines was an e-commerce apparel startup
                            created in early 2016 until late 2017, as a personal
                            side project combining graphic design and web
                            development skills. Apparel designs and product
                            photos created in Photoshop, website in
                            HTML + CSS + JS, hosting and payments processed
                            via Shopify.
                            </p>
                            <p>
                            In streetwear apparel startups, one common pitfall
                            is the sole reliance on simplistic wordmark designs,
                            as a method to increase brand awareness quickly.
                            However, when conducting market research it
                            appeared that this was only effective for already well
                            established brands, such as Supreme. The approach
                            for creating Double Nines was use of unique designs
                            and artwork as the focal point, with themed logos as a
                            complement.
                            </p>
                        </div>
                        <div className='imageBox'>
                            <img src='/img/doublenines_apparel.png' alt='Double Nines Apparel'/>
                        </div>
                        <div className='imageBox'>
                            <img src='/img/doublenines_logos.png' alt='Double Nines Apparel'/>
                        </div>
                    </section>
                </CSSTransition>
            </div>)
    }

    const five = (props) => {
        const {inViewport, forwardedRef} = props;
        return(
            <div className='parent-box' style={{zIndex: 5}}>
                <CSSTransition in={inViewport} exit={false} timeout={400} classNames='slideOver'>
                    <section ref={forwardedRef}>
                        
                        <span className='heroText'>contact</span>
                        <div className='contentBox'> 
                            <h2>Feel free to <a href='mailto:rk.chan@mail.utoronto.ca'>reach out to me!</a></h2>
                        </div>
                    </section>
                </CSSTransition>
            </div>)
    }
    
    const SectionOne = handleViewport(one, {threshold: 0.1})
    const SectionThree = handleViewport(three, {threshold: 0.1})
    const SectionFour = handleViewport(four, {threshold: 0.1})
    const SectionFive = handleViewport(five, {threshold: 0.1})

    return(
        <div className='showcase'>
            <TransitionGroup component={null}>
                <div className='anchor' id='about'/>
                <SectionOne/>
                <div className='anchor' id='projects'/>
                <SectionThree/>
                <SectionFour/>
                <div className='anchor' id='contact'/>
                <SectionFive/>
            </TransitionGroup>
        </div>
    )
}
export default Showcase