import React from 'react'
import Hero from './Hero'
import Showcase from './Showcase'
import './Main.css'

function Main() {

    const one = <a href='#about'><span>About</span><br/></a>
    const two = <a href='#projects'><span>Projects</span><br/></a>
    const three = <a href='/resume_may21.pdf' target='_blank'><span>Resume</span><br/></a>
    const four = <a href='#contact'><span>Contact</span></a>
    const links = [one, two, three, four]

    return(
        <div className='container'>
            <div className='content'>
                <div className='nav'>
                    <h1>Roy Chan</h1>
                    <div className='links'>
                        {links.map((link, i) => (
                                    <div className='link' style={{transitionDelay: `${i + 1}00ms`}}>{link}</div>
                        ))}
                    </div>
                </div>
                <section className='top'>
                    <Hero/>
                    <span className='filter'/>
                    <video playsinline autoPlay muted loop reverse src="/img/home.webm"/>
                </section>
                <Showcase/>
            </div>
            <div className='footer'>
                © 2022 roy chan
            </div>
        </div>
    )
}
export default Main