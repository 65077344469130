import React from 'react'

function Hero() {
    return(<div className='heroBox'>
        <div className='title'>
            <div className='subtitles'>
                <h2>Software Developer</h2>
                <h3>Computer Science and Statistics Major</h3>
                <h3>University of Toronto 2022</h3>
            </div>
        </div>
    </div>
    )
}
export default Hero